
import { Component, Vue, Watch } from "vue-property-decorator";
import * as TwitterServices from "@/services/TwitterServices";
import { FullTweet } from "@/types";
import { format, subWeeks } from "date-fns";
import { ROOT_ACTIONS } from "@/store/actions";
import CreateTwitterReportDialog from "@/components/dialogs/CreateTwitterReportDialog.vue";

@Component({
    components: {
        CreateTwitterReportDialog,
        TweetDetailsDialog: () => import("@/components/dialogs/TweetDetailsDialog.vue")
    }
})
export default class TwitterIndex extends Vue {
    modal = false
   
    // set this
    dates = [subWeeks(new Date(), 1).toISOString().substring(0, 10), new Date().toISOString().substr(0, 10)]
    tweets = [] as FullTweet[]
    loading = false
    selectedTweet = ''

    dialogs = {
        report: false,
        details: false,
    }

    stats: { count: number, likes: number, replies: number, retweets: number } = {
        count: 0,
        replies: 0,
        likes: 0,
        retweets: 0
    }

    openDetails(tweet: string) {
        this.selectedTweet = tweet
        this.dialogs.details = true
    }

    @Watch('dates', { immediate: true })
    async onDateChange() {
        if(!this.dates[0] || !this.dates[1]) return
        this.loading = true
        this.tweets = await TwitterServices.GetTweets({ start: new Date(this.dates[0]), end: new Date(this.dates[1]) }, {})
        this.loading = false
        this.stats = await TwitterServices.GetStats(new Date(this.dates[0]), new Date(this.dates[1]))
    }

    formatDate(date: string) {
        return format(new Date(date), "dd-MM-yyyy HH:mm")
    }

    async refreshDate() {
        const date = prompt('Datum (YYYY-MM-DD)')
        if (!date) return
        await TwitterServices.RefreshTweets(date as string)
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: "success",
            text: "Tweets zijn geupdate"
        })
    }
}
