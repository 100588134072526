import api from '@/plugins/axios';
import { FullTweet } from '@/types';

export const GetTweets = async (range: {start: Date, end: Date}, search: Partial<FullTweet>) => {
    const { data } = await api.get<FullTweet[]>(`/twitter/tweets/list`, {
        params: {
            start: range.start.toISOString(),
            end: range.end.toISOString(),
            ...search
        }
    });
    return data
}


export const RefreshTweets = async (date: string) => {
    const { data } = await api.post(`/twitter/tweets/refresh?day=${date}`);
    return data
}

export const GetStats = async (start: Date, end: Date) => {
    const { data } = await api.get<{ count: number, likes: number, replies: number, retweets: number }>(`/twitter/tweets/stats`, {
        params: {
            start: start.toISOString(),
            end: end.toISOString()
        }
    });
    return data
}

export const GetTweet = async (id: string) => {
    const { data } = await api.get<FullTweet>(`/twitter/tweets/${id}`);
    return data
}