
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { subWeeks, eachDayOfInterval, format, } from 'date-fns'
import * as TwitterServices from '@/services/TwitterServices'

@Component
export default class CreateTwitterReportDialog extends Vue {
    @VModel({ required: true }) show!: boolean;

    // set this
    dates = [subWeeks(new Date(), 1).toISOString().substring(0, 10), new Date().toISOString().substr(0, 10)]


    loading = false
    progress = 0;
    reportType = 'Samenvatting'


    async getSummaryData(allDates: Date[]) {
        let data = [[
            'Datum',
            'Aantal tweets',
            'Aantal likes',
            'Aantal retweets',
        ]] as any[][]

        for (let date of allDates) {
            let x = date;
            x.setHours(0, 0, 0, 0);
            let end = new Date(x);
            end.setHours(23, 59, 59, 999);
            const tweets = await TwitterServices.GetTweets({ start: x, end: end }, {})

            this.progress += 1 / allDates.length * 100
            data.push([
                format(date, 'dd-MM-yyyy'),
                tweets.filter(e => e.ourTweet).length,
                tweets.filter(e => e.ourTweet).reduce((a, b) => a + b.TweetLikes.length, 0),
                tweets.filter(e => e.ourTweet).reduce((a, b) => a + b.Retweets.length, 0),
            ])
        }

        return data
    }

    async getTweetsData(allDates: Date[]) {
        let data = [[
            'Datum',
            'Tweet ID',
            'Tweet Content',
            'Likes',
            'Retweets',
            'Onze Tweet',
            'Sentiment Positief',
        ]] as any[][]
        for (let date of allDates) {
            let x = date;
            x.setHours(0, 0, 0, 0);
            let end = new Date(x);
            end.setHours(23, 59, 59, 999);
            const tweets = await TwitterServices.GetTweets({ start: x, end: end }, {})

            for (let tweet of tweets) {
                this.progress += 1 / allDates.length / tweets.length * 100
                data.push([
                    format(date, 'dd-MM-yyyy'),
                    tweet.id,
                    tweet.text.replaceAll('\n', ' '),
                    tweet.TweetLikes.length,
                    tweet.Retweets.length,
                    tweet.ourTweet,
                    tweet.sentiment === null ? 'Niet berekend' : tweet.sentiment
                ])
            }
            this.progress += 1 / allDates.length * 100
        }

        return data
    }


    async submit() {
        // fetch the data per day one by one
        // dates are 2 iso strings with start and end
        this.loading = true
        const allDates = eachDayOfInterval({
            start: new Date(this.dates[0]),
            end: new Date(this.dates[1])
        })

        // this.reporttype can be summary or tweets

        let data = this.reportType === 'Samenvatting' ? await this.getSummaryData(allDates) : await this.getTweetsData(allDates)
        this.progress = 0

        const csvContent = data.map(row => row.join('|')).join('\n');
        console.log(csvContent)
        const blob = new Blob([csvContent], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `twitter-report_${this.dates[0]}_${this.dates[1]}.csv`;

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger a click event to initiate the download
        link.click();

        // create the csv file
        // download it

        this.loading = false
    }
}
